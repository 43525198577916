let environmentName = "staging2";
let config = {
    orderBucketUrl: "https://s3.console.aws.amazon.com/s3/buckets/cs2-staging2-eu-west-1-order-data-bucket/OrderData/",
    diagnosticApiHost: "https://api." + environmentName + ".diagnostics.closesimple.com/",
    adminAuthApiHost: "https://api.auth" + environmentName + ".admin.closesimple.com/",
    adminAuthHost: "https://auth" + environmentName + ".admin.closesimple.com/",
    cognitoClientId: "77bgl0q2tvo89c6bn0ndkrmaln"
};

config.tokenUrl = config.adminAuthHost + "authorize?response_type=code&client_id=" + config.cognitoClientId + "&redirect_uri=" + window.location.origin + "&scope=openid+profile+aws.cognito.signin.user.admin&idp_identifier=closesimple.com&state=";

config.apiOptions = (method, body) => {
    var options = {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'AnyVal'
        },
        credentials: "include"
    };
    if (body) {
        options.body = body;
    }
    return options;
};

module.exports = config;